import { CodeAuth as AgeroCodeAuth, AuthConfig } from 'agero-js-identity';
import { AUTH_PROVIDER_MAP } from 'constants/AuthProviderMap';

export class CodeAuth extends AgeroCodeAuth {
  constructor(config: AuthConfig) {
    super(config);
  }

  /**
   * Converts email address to domain based on swappable domains
   *
   * @param {string} email
   * @memberof CodeAuth
   */
  convertAuthEmail = (email: string): string => {
    const emailSplit = email.split('@');
    const domain = emailSplit[1].trim().toLowerCase();
    const replacementProvider = AUTH_PROVIDER_MAP.find(
      (provider) => provider.authEmailDomain === domain
    );

    if (replacementProvider) {
      return `${emailSplit[0]}@${replacementProvider.replacementDomain}`;
    }

    return email;
  };

  /**
   * Retrieves user's name from token. Fails over to base getUserName if name and email aren't provided.
   *
   * @memberof CodeAuth
   */
  getName = (): string => {
    try {
      const { email, family_name, given_name } = this.getDecodedIdToken();
      if (given_name && family_name) {
        // Prefer the given name and family name
        return `${given_name} ${family_name}`;
      }
      // Fallback to email, then to cognito:username
      return email || this.getUsername();
    } catch {
      // If there is an error, default to value
      return 'unknown';
    }
  };

  /**
   * Get user's initials for display. Grabs first character of email if name isn't provided.
   *
   * @memberof CodeAuth
   */
  getInitials = (): string => {
    try {
      const { email, family_name, given_name } = this.getDecodedIdToken();
      if (given_name && family_name) {
        // Prefer the given name and family name
        return `${given_name.charAt(0)}${family_name.charAt(0)}`;
      }
      // Fallback to email, then to cognito:username
      return email.charAt(0);
    } catch {
      return '';
    }
  };

  /**
   * Retrieves user's email from token.
   *
   * @memberof CodeAuth
   */
  getEmail = (): string => {
    try {
      return this.getDecodedIdToken()?.email;
    } catch {
      return '';
    }
  };

  /**
   * Gets available AgentIds from auth token.
   *
   * @memberof CodeAuth
   */
  getAgentIds = (): string[] => {
    const authorizedApps = this.getAuthorizedApps();
    return authorizedApps
      .filter(
        (app) => app.split(':')[1] === 'basic' || app.split(':')[1] === 'sl'
      )
      .map((app) => app.split(':')[2])
      .filter((agencyId) => agencyId !== '*');
  };

  /**
   * Checks if user has read all access
   *
   * @memberof CodeAuth
   */
  hasAllReadAccess = () => {
    const authorizedApps = this.getAuthorizedApps();
    const allReadAccessStr = authorizedApps.find((app) => {
      const role = app.split(':')[1];
      const context = app.split(':')[2];
      return (
        (role === 'basic' ||
          role === 'admin' ||
          role === 'superadmin' ||
          role === 'support') &&
        context === '*'
      );
    });
    return allReadAccessStr !== undefined;
  };

  /**
   * Verifies user has access to app.
   *
   * @param {string} app
   * @memberof CodeAuth
   */
  isAuthorizedForApp = (app: string): boolean => {
    // Authorized app syntax is '<app>:<permission>:<context>', e.g. 'aid:admin:*' or 'aid:basic:12345'
    try {
      const decodedToken = this.getDecodedIdToken();
      const authAppsString = decodedToken.authorizedApps;
      const authorizedApps: string[] = JSON.parse(authAppsString);
      return authorizedApps.includes(app);
    } catch {
      // If there is an error, default to unauthorized.
      return false;
    }
  };

  /**
   * Gets all apps user is authorized for
   *
   * @memberof CodeAuth
   */
  getAuthorizedApps = () => {
    try {
      const decodedToken = this.getDecodedIdToken();
      const authAppsString = decodedToken.authorizedApps;
      const authorizedApps: string[] = JSON.parse(authAppsString);
      return authorizedApps.filter((aa) => aa.startsWith('aid'));
    } catch (err) {
      return [];
    }
  };

  /**
   * Get domain name from email address
   *
   * @memberof CodeAuth
   */
  getDomain = (): string => {
    try {
      return this.getDecodedIdToken()?.email?.split('@')[1];
    } catch {
      return '';
    }
  };

  /**
   * Retrieves user's first name from token.
   *
   * @memberof CodeAuth
   */
  getFirstName = (): string => {
    try {
      return this.getDecodedIdToken()?.given_name;
    } catch {
      return '';
    }
  };

  /**
   * Retrieves user's last name from token.
   *
   * @memberof CodeAuth
   */
  getLastName = (): string => {
    try {
      return this.getDecodedIdToken()?.family_name;
    } catch {
      return '';
    }
  };

  /**
   * Parses user's apps and verifies if they are an system/super admin
   *
   * @memberof CodeAuth
   */
  isSystemAdmin = (): boolean => {
    const authorizedApps = this.getAuthorizedApps();
    const splitApp =
      authorizedApps
        .filter((aa) => aa.startsWith('aid:superadmin'))[0]
        ?.split(':') || [];
    const userRole = splitApp[1];
    return (userRole && userRole === 'superadmin') === true;
  };

  /**
   * Parses user's apps and verifies if they are an client/Tenant/Organization Admin
   *
   * @memberof CodeAuth
   */
  isClientAdmin = (): boolean => {
    const authorizedApps = this.getAuthorizedApps();
    const splitApp =
      authorizedApps
        .filter((aa) => aa.startsWith('aid:admin'))[0]
        ?.split(':') || [];
    const userRole = splitApp[1];
    return (userRole && userRole === 'admin') === true;
  };

  /**
   * Parses user's apps and verifies if they are an Support Specialist/Global Support
   *
   * @memberof CodeAuth
   */
  isSupportSpecialist = (): boolean => {
    const authorizedApps = this.getAuthorizedApps();
    const splitApp =
      authorizedApps
        .filter((aa) => aa.startsWith('aid:support'))[0]
        ?.split(':') || [];
    const userRole = splitApp[1];
    return (userRole && userRole === 'support') === true;
  };

  /**
   * Parses user's apps and verifies if they are an Sales Leader has access to multiple office code
   *
   * @memberof CodeAuth
   */
  isSalesLeader = (): boolean => {
    const authorizedApps = this.getAuthorizedApps();
    const splitApp =
      authorizedApps.filter((aa) => aa.startsWith('aid:sl'))[0]?.split(':') ||
      [];
    const userRole = splitApp[1];
    return (userRole && userRole === 'sl') === true;
  };

  /**
   * Parses user's apps and verifies if they are an Manager/Team Lead/Agency Staff has access to multiple office code
   *
   * @memberof CodeAuth
   */
  isManager = (): boolean => {
    return this.getAgentIds().length > 1 === true;
  };

  /**
   * Parses user's apps and verifies if they are reguarl/End user /singe office code
   *
   * @memberof CodeAuth
   */
  isAgent = (): boolean => {
    const authorizedApps = this.getAuthorizedApps();
    const splitApp =
      authorizedApps
        .filter((aa) => aa.startsWith('aid:basic'))[0]
        ?.split(':') || [];
    const userRole = splitApp[1];
    return (
      (userRole && userRole === 'basic' && this.getAgentIds().length == 1) ===
      true
    );
  };

  /**
   * Parses client code/identifier of the user
   *
   * @memberof CodeAuth
   */

  getClientCode = (): string => {
    const clientCodes = this.getDecodedIdToken()?.clientCodes;
    const code = clientCodes ? JSON.parse(clientCodes) : [];
    return code.length ? code[0] : '';
  };
}
