import { ChangeEvent, FC, useState } from 'react';
import validator from 'validator';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export interface LoginFormProps {
  isLoading: boolean;
  errorMessage?: string;
  onSubmit: (email: string) => void;
}

/**
 * Portable login form. For use on login screen or popup if needed  in future.
 *
 * @param {*} {
 *   onSubmit,
 *   isLoading,
 *   errorMessage,
 * }
 * @return {*}
 */
const LoginForm: FC<LoginFormProps> = ({
  onSubmit,
  isLoading,
  errorMessage,
}) => {
  const [hasEmailError, setHasEmailError] = useState(true);
  const validateEmail = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setHasEmailError(!validator.isEmail(e.target.value));
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString();

    if (email && validator.isEmail(email)) {
      onSubmit(email);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ m: 3, textAlign: 'center' }}
      >
        {errorMessage && (
          <Typography
            sx={(theme) => ({
              mb: 3,
              color: theme.namedColors.primary.default,
            })}
            data-testid="error-message"
          >
            {errorMessage}
          </Typography>
        )}
        <TextField
          sx={{ mb: 2 }}
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          onChange={validateEmail}
          autoFocus
        />
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || hasEmailError}
          sx={{
            py: 1,
            my: 2,
            width: 200,
            '&.Mui-focusVisible': {
              backgroundColor: '#95180b',
            },
            '& .MuiTouchRipple-root span': {
              opacity: 0.3,
            },
          }}
        >
          Continue
        </Button>

        <Divider sx={{ mb: 2 }} />
        <Typography sx={{ mb: 1 }}>
          Have access to multiple offices? Enter the email address associated
          with the office you'd like to see.
        </Typography>
        <Typography variant="body2">Trouble accessing your account?</Typography>
        <Typography variant="body2">
          Please contact your System Administrator.
        </Typography>
      </Box>
    </Card>
  );
};

export default LoginForm;
